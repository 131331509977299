window.load_air_datepickers = function() {
  $('.air-datepicker').each((index, element) => {
    const $el = $(element)
    var initialDate = new Date($el.attr('data-initial'))
    var formattedDate = new Date(initialDate.getFullYear(),initialDate.getMonth(),initialDate.getDate()+1);
    var options = {
      language: "en",
      dateFormat: "MM d",
      minDate: new Date("January 1, 2017"),
      maxDate: new Date("December 31, 2017")
    }
    $el.attr('type', 'text').datepicker(options)
    $el.data('datepicker').selectDate(formattedDate)
  });
  $('.reset-air-datepickers').on('click', function(){
    $('#hourly_range_start_date').val("January 1");
    $('#hourly_range_end_date').val("December 31");
    return false;
  });
}

document.addEventListener("DOMContentLoaded", function(event) {
  window.load_air_datepickers();
})