window.formatConsumption = function(kilowatt_hours) {
  let watt_hours = kilowatt_hours * 1000.0;
  let btu_hours = kilowatt_hours * 3412.142;
  let cooling_ton_hours = kilowatt_hours * 0.284;
  let steam_pound_hours = kilowatt_hours * 3.281;

  return formatWattHours(watt_hours) + '<br>' +
    formatBtuHours(btu_hours) + '<br>' +
    formatCoolingTonHours(cooling_ton_hours) + '<br>' +
    formatSteamPoundHours(steam_pound_hours);
}

window.formatWattHours = function(value) {
  let roundAmount = 1;
  let u = -1;
  let thresh = 1000;
  let units = ['kWh','MWh','GWh','TWh'];

  if (Math.abs(value) < thresh) {
    return `${value.toFixed(roundAmount)} Wh`;
  }

  do {
    value /= thresh;
    ++u;
  } while(Math.abs(value) >= thresh && u < units.length - 1);

  return `${value.toFixed(roundAmount)} ${units[u]}`;
}

window.formatIntensity = function(value) {
  let roundAmount = 1;
  let u = -1;
  let thresh = 1000;
  let units = ['kW/m²','MW/m²','GW/m²','TW/m²'];

  if (Math.abs(value) < thresh) {
    return `${value.toFixed(roundAmount)} W/m²`;
  }

  do {
    value /= thresh;
    ++u;
  } while(Math.abs(value) >= thresh && u < units.length - 1);

  return `${value.toFixed(roundAmount)} ${units[u]}`;
}

window.formatBtuHours = function(value) {
  let roundAmount = 1;
  let u = -1;
  let thresh = 1000;
  let units = ['kBTUh','MBTUh','GBTUh','TBTUh'];

  if (Math.abs(value) < thresh) {
    return `${value.toFixed(roundAmount)} BTUh`;
  }

  do {
    value /= thresh;
    ++u;
  } while(Math.abs(value) >= thresh && u < units.length - 1);

  return `${value.toFixed(roundAmount)} ${units[u]}`;
}

window.formatCoolingTonHours = function(value) {
  let roundAmount = 1;
  return `${value.toFixed(roundAmount)} Cooling Tons`;
}

window.formatSteamPoundHours = function(value) {
  let roundAmount = 1;
  let u = -1;
  let thresh = 1000;
  let units = ['Klbs of steam','Mlbs of steam','Glbs of steam','Tlbs of steam'];

  if (Math.abs(value) < thresh) {
    return `${value.toFixed(roundAmount)} lbs of steam`;
  }

  do {
    value /= thresh;
    ++u;
  } while(Math.abs(value) >= thresh && u < units.length - 1);

  return `${value.toFixed(roundAmount)} ${units[u]}`;
}

window.formatCarbon = function(metric_tons) {
  return `${metric_tons.toFixed(0)} MT`;
}

window.formatDemand = function(kilowatts) {
  let watts = kilowatts * 1000.0;
  let roundAmount = 1;
  let u = -1;
  let thresh = 1000;
  let units = ['kW','MW','GW','TW'];

  if (Math.abs(watts) < thresh) {
    return `${watts.toFixed(roundAmount)} W`;
  }

  do {
    watts /= thresh;
    ++u;
  } while(Math.abs(watts) >= thresh && u < units.length - 1);

  return `${watts.toFixed(roundAmount)} ${units[u]}`;
}

window.formatCostWithSymbol = function(dollars, symbol) {
  return `${symbol}${dollars.toFixed(2)}`;
}

window.formatCostAgain = function(dollars) {
  let u = -1;
  let thresh = 1000;
  let units = ['K','M','B','T'];

  if (Math.abs(dollars) < thresh) {
    return `$${dollars.toFixed(2)}`;
  }

  do {
    dollars /= thresh;
    ++u;
  } while(Math.abs(dollars) >= thresh && u < units.length - 1);

  return `$${dollars.toFixed(1)} ${units[u]}`;
}
