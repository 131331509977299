// This sizes Highcharts charts  to their
// container's new width after certain events
// occur on the page.

// Below you can se we are resizing it when
// the secondary aside menu is opened or closed
// - NCC @ BNB - July 1, 2019

function delayResizingHighcharts(){
  setTimeout(function(){
    resizeHighcharts();
  }, 200);
}

function resizeHighcharts(){
  var arrayLength = Highcharts.charts.length;
  for (var i = 0; i < arrayLength; i++) {
    chart = Highcharts.charts[i];
    chart.update({
      chart: {
        width: chart.renderTo.clientWidth
      }
    });
  }
}

document.addEventListener("DOMContentLoaded", function(event) {
  $('a.kt-aside-secondary__nav-toolbar-icon[data-toggle="tab"]').on('click', function (e) {
    delayResizingHighcharts()
  });
  $('button.kt-aside-secondary__close').on('click', function (e) {
    delayResizingHighcharts()
  });
  $('button.kt-aside__brand-aside-toggler').on('click', function (e) {
    delayResizingHighcharts()
  });

  
})