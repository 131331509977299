// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("leaflet")
require("leaflet.markercluster")
require("uislider")
require("air_datepicker")
require("stacked_modals")
window.Highcharts = require("highcharts")
window.addMore = require("highcharts/highcharts-more")
window.addMore(window.Highcharts)
window.data = require("highcharts/modules/data")
window.data(window.Highcharts)
window.exporting = require("highcharts/modules/exporting")
window.exporting(window.Highcharts)
window.export_data = require("highcharts/modules/export-data")
window.export_data(window.Highcharts)
window.heatmap = require("highcharts/modules/heatmap")
window.heatmap(window.Highcharts)
window.treemap = require("highcharts/modules/treemap")
window.treemap(window.Highcharts)
window.sankey = require("highcharts/modules/sankey")
window.sankey(window.Highcharts)
window.solid_gauge = require("highcharts/modules/solid-gauge")
window.solid_gauge(window.Highcharts)
window.boost = require("highcharts/modules/boost")
window.boost(window.Highcharts)
window.boostCanvas = require("highcharts/modules/boost-canvas")
window.boostCanvas(window.Highcharts)
window.draggable_points = require("highcharts/modules/draggable-points")
window.draggable_points(window.Highcharts)
window.highchartsGroupedCategories = require("highcharts-grouped-categories/grouped-categories")
window.highchartsGroupedCategories(window.Highcharts)
window.highchartsAnnotations = require("highcharts/modules/annotations")
window.highchartsAnnotations(window.Highcharts)
window.variwide = require("highcharts/modules/variwide")
window.variwide(window.Highcharts)
window.introJs = require("intro.js")
require("auto_resizing_highcharts_charts")
require("javascript_number_formatters")
require("please_wait_silly")

// Sometimes we want to share javascript that uses JQuery.
// If JQuery isn't loaded yet, we need to wait for the page
// to be loaded. If we're just rendering something, we want
// the JS to be executed immediately. This allows us to
// do either. See _assosication_tag.html.haml for an example.
// Ideally, Keen (which has jquery) would be included in the
// head, along with the packs. But that feels like a major
// infrastructure change, and this is a decent interim solution.
// ANH @ BNB 2/12/2020
let Rosetta = {}
Rosetta.init = (work) => {
  if (window.$) {
    $(work)
  } else {
    document.addEventListener("DOMContentLoaded", work)
  }
}

window.Rosetta = Rosetta
