window.load_sliders = function(scope_selector = 'body') {
  $( scope_selector + " .ui-slider-factor" ).each(function( index ) {
    var slider = this
    var textInput = $(this).closest('.slider-container').find('input')[0]
    noUiSlider.create(this, {
      start: textInput.value,
      direction: 'rtl',
      range: {
          'min': 0,
          'max': 2.0
      },
      connect: "lower",

      // Put '0' at the bottom of the slider
      orientation: 'vertical',

    });
    slider.noUiSlider.on('update', function( values, handle ) {
      textInput.setAttribute('value', values[handle]);
    });
    textInput.addEventListener('change', function(){
      slider.noUiSlider.set(this.value);
    });
  });

  $( scope_selector + " .horizontal-slider" ).each(function( index ) {
    var slider = $(this).find('.slider')[0]
    var textInput = $(this).find('input')[0]
    noUiSlider.create(slider, {
      start: textInput.value,
      direction: 'ltr',
      range: {
          'min': Number($(textInput).data("min")),
          'max': Number($(textInput).data("max"))
      },
      connect: "lower",
      step: Number($(textInput).data("step")),

      // Put '0' at the bottom of the slider
      orientation: 'horizontal',

    });
    slider.noUiSlider.on('update', function( values, handle ) {
      textInput.setAttribute('value', values[handle]);
    });
    textInput.addEventListener('change', function(){
      slider.noUiSlider.set(this.value);
    });
  });

  $( scope_selector + " .horizontal-integer-slider" ).each(function( index ) {
    var slider = $(this).find('.slider')[0]
    var textInput = $(this).find('input')[0]
    noUiSlider.create(slider, {
      start: textInput.value,
      direction: 'ltr',
      range: {
          'min': Number($(textInput).data("min")),
          'max': Number($(textInput).data("max"))
      },
      connect: "lower",
      step: Number($(textInput).data("step")),

      // Put '0' at the bottom of the slider
      orientation: 'horizontal',
      format: {
        to: function(value) {
          return value.toFixed(0);
        },
        from: function (value) {
            return Number(value);
        }
      }

    });
    slider.noUiSlider.on('update', function( values, handle ) {
      textInput.setAttribute('value', values[handle]);
    });
    textInput.addEventListener('change', function(){
      slider.noUiSlider.set(this.value);
    });
  });
}

window.load_adjuster_sliders = function(scope_selector = 'body') {
  $( scope_selector + " .ui-slider-factor" ).each(function( index ) {
    var slider = this
    var textInput = $(this).closest('.slider-container').find('input')[0]
    noUiSlider.create(this, {
      start: textInput.value,
      direction: 'rtl',
      range: {
          'min': 0,
          'max': Number($(textInput).data("max"))
      },
      connect: "lower",
      step: 0.000001,

      // Put '0' at the bottom of the slider
      orientation: 'vertical',

    });
    slider.noUiSlider.on('update', function( values, handle ) {
      textInput.setAttribute('value', values[handle]);
    });
    textInput.addEventListener('change', function(){
      slider.noUiSlider.set(this.value);
    });
  });
}

window.load_energy_demand_intensity_multiplier_slider = function() {
  $( ".energy-demand-intensity-multiplier-slider" ).each(function( index ) {
    var slider = this;
    var textInput = $(this).closest('.slider-container').find('input')[0];
    var resetLink = $(this).closest('.slider-container').find('a')[0];
    var valueThing = document.getElementById('energy-demand-intensity-multiplier-slider-value');
    noUiSlider.create(this, {
      start: textInput.value,
      // second value in array is steps size for that range
      range: {
          'min': [0.01],
          '20%': [0.1],
          '60%': [2.0, 0.05],
          '80%': [5.0, 0.5],
          '90%': [10, 1],
          'max': 100
      },
    });
    slider.noUiSlider.on('update', function( values, handle ) {
      textInput.setAttribute('value', values[handle]);
      valueThing.innerHTML = values[handle];
    });
    textInput.addEventListener('change', function(){
      slider.noUiSlider.set(this.value);
    });
    $(resetLink).on('click', function(e) {
      slider.noUiSlider.set(1);
      return false;
    });
  });
}
