function update_please_wait_silly(){
  myArray = [
    "Measuring Hourly Energy Demands...",
    "Verifying System Installations...",
    "Validating Building Parameters...",
    "Generating Calibrated Values...",
    "Seeking Cal's Approval...",
    "Capturing Screencast for Aly...",
    "Accessing JT's Mind Palace...",
    "Deconstructing SSIMde...",
    "Restarting Microsoft Excel..."
  ]
  var rand = myArray[Math.floor(Math.random() * myArray.length)];
  $("#swal2-content").html(rand);
}
window.please_wait_silly = function (){
  Swal.fire({
    title: "Please Wait",
    text: "Copying tigers",
    type: "info",
    showConfirmButton: false
  });
  setInterval(update_please_wait_silly,200);
}